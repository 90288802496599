\:root
  --headerFont: Cambria, "Times New Roman", Georgia, "DejaVu Serif", serif
  --faqFont: Georgia, "Times New Roman", "DejaVu Serif", serif
  --linkColor: #5487aa
  --hoverLinkColor: var(--linkColor)
  --activeLinkColor: var(--linkColor)
  --visitedLinkColor: #6a405d

html,
body
  margin: 0
  padding: 0
  font-family: Verdana, Tahoma, "DejaVu Sans", sans-serif
  font-size: 14px

*
  box-sizing: border-box

h1,
h2,
h3
  font-family: var(--headerFont)

h1
  font-size: 25px

h2,
h3
  font-size: 18px

a
  color: var(--linkColor)
  text-decoration: none

  &:visited
    color: var(--visitedLinkColor)

  &:hover
    color: var(--hoverLinkColor)
    text-decoration: underline

  &.active,
  &:active
    color: var(--activeLinkColor)

.linkButton
  display: inline
  margin: 0
  padding: 0
  border: none
  background: transparent
  color: var(--linkColor)
  font-size: inherit
  text-align: left
  cursor: pointer

.ta-center
  text-align: center

.ta-right
  text-align: right

.yt-lite
  max-width: 580px
  margin: auto

.epigraph
  width: 50%
  max-width: 340px
  min-width: 250px
  text-indent: 0 !important
  align-self: flex-end

.chapter
  margin-left: 2.6em

.book-nav
  width: 220px
  font-family: Verdana, Tahoma, Arial, sans-serif
  font-size: 11px
  margin-top: 2.5em
  margin-bottom: 0
  line-height: 1.2em
  padding: 1em
  background: url(../dist/images/z2.jpg) no-repeat bottom, url(../dist/images/z1.jpg) no-repeat top, #e9ebfc

  h2
    font-family: Cambria, "Times New Roman", Georgia, "DejaVu Serif", serif
    font-size: 1.6em
    margin: 1em 0
    color: black
    text-align: center

  & > ul
    margin-left: 0
    padding-left: 0
    list-style: none
    margin-top: .5em

    & > li
      padding-left: 1.2em
      background-repeat: no-repeat
      background-position: -.4em 0
      font-weight: bold
      margin-top: .7em

      a.active
        &,
        &:hover,
        &:active
          color: #000
          text-decoration: none
          cursor: pointer
          pointer-events: none

      &.no-children
        background-image: url(../dist/images/CircleSmall.png)

      &.closed
        background-image: url(../dist/images/TriangleSmall.png)

      &.open
        background-image: url(../dist/images/TriangleDownSmall.png)

      .underlined
        border-bottom: 1px dotted #888
        cursor: pointer

      & > ul
        margin: 0.9em 0
        padding-left: 1.2em
        font-size: 11px
        font-weight: normal

        & > li
          margin-top: 0.5em

.bedrograd-nav
  display: flex
  justify-content: space-between
  font-size: .8em

  a
    display: flex
    align-items: center

    img
      margin: 0 10px
