.CharactersPage
  font-size: 15px
  font-family: Georgia, "Times New Roman", "DejaVu Serif", serif

  h2
    font-size: 20px

  h3,
  h4
    margin-bottom: 0
    font-family: Georgia, "Times New Roman", "DejaVu Serif", serif
    font-size: 20px
    font-style: italic
    color: #999999
    font-weight: normal

  p
    margin-top: 5px

  &_Brov
    display: flex
    justify-content: flex-end
    align-items: center

    & > img
      max-width: 100%

    & > section
      display: flex
      flex-direction: column
      justify-content: center

    @media (max-width: 540px)
      flex-direction: column

  &_Subheading
    color: #999999
    font-size: 11px
    font-family: Verdana, Tahoma, "DejaVu Sans", sans-serif

  &_Small
    font-size: 11px
    font-family: Verdana, Tahoma, "DejaVu Sans", sans-serif

  &_TwoColumns
    display: grid
    grid-template-columns: 1fr 1fr

    @media (max-width: 640px)
      grid-template-columns: 1fr

  &_GebniBig
    max-width: 100%

    @media (max-width: 640px)
      display: none

  &_GebniSmall
    max-width: 100%

    @media (min-width: 640px)
      display: none

  &_Gebni
    grid-gap: 30px

    h3,
    .CharactersPage_Subheading
      text-align: center

  &_OtherPolitics
    grid-template-columns: 60% 1fr
    grid-gap: 40px

    @media (max-width: 640px)
      grid-template-columns: 1fr

    & > section
      &:first-child
        img
          float: left
          margin: 3px 10px

      &:last-child
        @media (min-width: 640px)
          text-align: right

  &_Other
    section
      &:first-child
        & > div
          flex-direction: row-reverse

          img
            margin: 0 5px 0 10px

      &:last-child
        & > div
          img
            margin: 0 10px 0 5px

      & > div
        display: flex
        align-items: flex-start

        @media (max-width: 640px)
          flex-direction: row !important

          img
            margin: 0 10px 0 0 !important

        @media (max-width: 425px)
          flex-direction: column !important
          align-items: center
