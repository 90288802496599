.levels
  &--official
    font-family: "Courier New", "DejaVu Sans Mono", monospace

  &--comment
    font-family: Georgia, "Times New Roman", "DejaVu Serif", serif
    font-style: italic
    font-size: 15px
    margin-left: 15px
    padding-bottom: 1em
    color: #858585

blockquote
  &.levels
    &--comment
      margin-left: 40px