.disclamer
  display: flex
  align-items: center
  justify-content: center
  min-height: 100vh

  &_container
    max-width: 960px
    background: url('../../public/images/stamp.jpg') no-repeat 100% 75%

  &_links
    display: flex
    justify-content: space-between

  @media (max-width: 960px)
    &_container
      padding-right: 15px
      padding-left: 15px
      background-position: 100% 80%
