.newsArticle
  margin-bottom: 25px
  padding: 13px
  border: 2px dotted #c9a8c5
  font-size: 12px

  &-full
    border: 0
    padding: 0
    font-size: 14px

  img
    max-width: 90%

  h2
    margin: 0

  &_info
    color: #888
    font-size: 11px

  p
    margin-top: 0

  footer
    display: flex
    align-items: flex-end
    justify-content: space-between