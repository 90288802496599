.header
  &_image
    width: 100%
    padding-bottom: 11%
    background-image: url('../../../public/images/header.jpg')
    background-size: contain

  & > nav
    display: flex
    justify-content: space-between
    padding: 0 70px
    background: url('../../../public/images/menu.jpg') no-repeat top center

    @media (max-width: 1000px)
      padding: 0 10px
      overflow: auto

    a
      --linkColor: #FFF
      --hoverLinkColor: #ff70a4
      --activeLinkColor: var(--hoverLinkColor)
      --visitedLinkColor: var(--linkColor)
      position: relative
      padding: 10px 10px
      font-weight: bold
      font-family: Cambria, "Times New Roman", Georgia, "DejaVu Serif", serif
      font-size: 14px
      white-space: nowrap

      &:focus-visible
        border: 2px solid #ff0000

      &.header_link-withSubmenu
        @media (min-width: 1000px)
          padding-right: 15px

          &:after
            content: '▿'
            position: absolute
            right: 0

      & > section
        display: none
        position: absolute
        flex-direction: column
        top: 100%
        min-width: max-content
        background: #6b617a

        &.header_link_subMenu-shown
          display: flex