.share_button
  --share-bg-h: 319
  --share-bg-s: 25%
  --share-bg-l: 44%
  display: block !important
  width: 200px
  margin: 0 0 5px
  padding: 5px 0 !important
  border: 0
  border-radius: 5px
  color: #FFF !important
  font-size: 14px !important
  background: hsl(var(--share-bg-h), var(--share-bg-s), var(--share-bg-l)) !important
  cursor: pointer

  &:hover
    background: hsl(var(--share-bg-h), var(--share-bg-s), calc(var(--share-bg-l) - 10%)) !important

  &:active
    background: hsl(var(--share-bg-h), var(--share-bg-s), calc(var(--share-bg-l) - 15%)) !important

  &-copied
    --share-bg-h: 124

  &-twitter
    --share-bg-h: 197
    --share-bg-s: 100%
    --share-bg-l: 47%

  &-vk
    --share-bg-h: 212
    --share-bg-s: 50%
    --share-bg-l: 52%

  &-facebook
    --share-bg-h: 221
    --share-bg-s: 44%
    --share-bg-l: 41%