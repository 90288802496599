.disclamer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
.disclamer_container {
  max-width: 960px;
  background: url("stamp.5eba9d7c.jpg") no-repeat 100% 75%;
}
.disclamer_links {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 960px) {
  .disclamer_container {
    padding-right: 15px;
    padding-left: 15px;
    background-position: 100% 80%;
  }
}
@charset "UTF-8";
.header_image {
  width: 100%;
  padding-bottom: 11%;
  background-image: url("header.12461298.jpg");
  background-size: contain;
}
.header > nav {
  display: flex;
  justify-content: space-between;
  padding: 0 70px;
  background: url("menu.bf2a13f3.jpg") no-repeat top center;
}
@media (max-width: 1000px) {
  .header > nav {
    padding: 0 10px;
    overflow: auto;
  }
}
.header > nav a {
  --linkColor: #FFF;
  --hoverLinkColor: #ff70a4;
  --activeLinkColor: var(--hoverLinkColor);
  --visitedLinkColor: var(--linkColor);
  position: relative;
  padding: 10px 10px;
  font-weight: bold;
  font-family: Cambria, "Times New Roman", Georgia, "DejaVu Serif", serif;
  font-size: 14px;
  white-space: nowrap;
}
.header > nav a:focus-visible {
  border: 2px solid #ff0000;
}
@media (min-width: 1000px) {
  .header > nav a.header_link-withSubmenu {
    padding-right: 15px;
  }
  .header > nav a.header_link-withSubmenu:after {
    content: "▿";
    position: absolute;
    right: 0;
  }
}
.header > nav a > section {
  display: none;
  position: absolute;
  flex-direction: column;
  top: 100%;
  min-width: max-content;
  background: #6b617a;
}
.header > nav a > section.header_link_subMenu-shown {
  display: flex;
}
.page {
  display: flex;
  justify-content: center;
}
.page_wrapper {
  width: 100%;
  max-width: 1000px;
}
@media (max-width: 1000px) {
  .page main,
.page aside {
    max-width: 100vw;
    padding: 0 15px;
  }
}
.page--noSidebar {
  grid-template-columns: 1fr;
  grid-template-areas: "main" "footer";
}
.page--sidebarRight {
  grid-template-areas: "main main main sidebar" "footer footer footer footer";
}
@media (max-width: 1000px) {
  .page--sidebarRight {
    grid-template-areas: "main" "sidebar" "footer";
  }
}
.page--sidebarLeft {
  grid-template-areas: "sidebar main main main" "footer footer footer footer";
}
@media (max-width: 1000px) {
  .page--sidebarLeft {
    grid-template-areas: "sidebar" "main" "footer";
  }
}
.page--sidebarLeft, .page--sidebarRight {
  display: grid;
  grid-gap: 15px;
  margin-top: 15px;
}
.page--sidebarLeft main, .page--sidebarRight main {
  grid-area: main;
}
.page--sidebarLeft aside, .page--sidebarRight aside {
  grid-area: sidebar;
}
.page--sidebarLeft footer, .page--sidebarRight footer {
  grid-area: footer;
}
@media (max-width: 1000px) {
  .page--sidebarLeft, .page--sidebarRight {
    grid-template-columns: 1fr;
  }
}
.page--withText {
  font-family: var(--faqFont);
  font-Size: 16px;
}
.page--withText p,
.page--withText h3 {
  text-indent: 30px;
}
.page--noIndent p,
.page--noIndent h3 {
  text-indent: 0 !important;
}
.page_footer {
  color: #888;
  font-weight: normal;
  font-size: 0.8em;
  text-align: right;
  font-style: italic;
}
@media (max-width: 1000px) {
  .page_footer {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
.newsArticle {
  margin-bottom: 25px;
  padding: 13px;
  border: 2px dotted #c9a8c5;
  font-size: 12px;
}
.newsArticle-full {
  border: 0;
  padding: 0;
  font-size: 14px;
}
.newsArticle img {
  max-width: 90%;
}
.newsArticle h2 {
  margin: 0;
}
.newsArticle_info {
  color: #888;
  font-size: 11px;
}
.newsArticle p {
  margin-top: 0;
}
.newsArticle footer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}


.yt-lite {
    background-color: #000;
    position: relative;
    display: block;
    contain: content;
    background-position: center center;
    background-size: cover;
    cursor: pointer;
}

/* gradient */
.yt-lite::before {
     content: '';
     display: block;
     position: absolute;
     top: 0;
     background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT+OqFAAAAdklEQVQoz42QQQ7AIAgEF/T/D+kbq/RWAlnQyyazA4aoAB4FsBSA/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg==);
     background-position: top;
     background-repeat: repeat-x;
     height: 60px;
     padding-bottom: 50px;
     width: 100%;
     transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}

/* responsive iframe with a 16:9 aspect ratio
    thanks https://css-tricks.com/responsive-iframes/
*/
.yt-lite::after {
    content: "";
    display: block;
    padding-bottom: calc(100% / (16 / 9));
}
.yt-lite > iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

/* play button */
.yt-lite > .lty-playbtn {
    width: 70px;
    height: 46px;
    background-color: #212121;
    z-index: 1;
    opacity: 0.8;
    border-radius: 14%; /* TODO: Consider replacing this with YT's actual svg. Eh. */
    transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}
.yt-lite:hover > .lty-playbtn {
    background-color: #f00;
    opacity: 1;
}
/* play button triangle */
.yt-lite > .lty-playbtn:before {
    content: '';
    border-style: solid;
    border-width: 11px 0 11px 19px;
    border-color: transparent transparent transparent #fff;
}

.yt-lite > .lty-playbtn,
.yt-lite > .lty-playbtn:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
}

/* Post-click styles */
.yt-lite.lyt-activated {
    cursor: unset;
}
.yt-lite.lyt-activated::before,
.yt-lite.lyt-activated > .lty-playbtn {
    opacity: 0;
    pointer-events: none;
}
@charset "UTF-8";
.BlockQuote {
  --quoteColor: #b7afcc;
  margin: 0;
  font-family: Georgia, "Times New Roman", "DejaVu Serif", serif;
}
.BlockQuote blockquote {
  --quoteSize: 108px;
  position: relative;
  margin: 0;
  padding: 0 70px;
  font-size: 16px;
}
.BlockQuote blockquote .quote_dots {
  margin: -14px 0 30px;
  color: var(--quoteColor);
  font-size: calc(var(--quoteSize) / 2);
  line-height: 0.3em;
}
.BlockQuote blockquote::before, .BlockQuote blockquote::after {
  position: absolute;
  color: var(--quoteColor);
  font-size: var(--quoteSize);
}
.BlockQuote blockquote::before {
  content: "«";
  top: -45px;
  left: 5px;
}
.BlockQuote blockquote::after {
  content: "»";
  bottom: -31px;
  right: 15px;
}
@media (max-width: 1000px) {
  .BlockQuote blockquote {
    --quoteSize: 50px;
    padding: 0 35px;
  }
  .BlockQuote blockquote .quote_dots {
    margin: -7px 0 20px;
  }
  .BlockQuote blockquote::before {
    top: -20px;
  }
  .BlockQuote blockquote::after {
    bottom: -14px;
  }
}
.BlockQuote figcaption {
  margin: 18px 0 38px auto;
  width: 40%;
  min-width: 300px;
  color: var(--quoteColor);
  font-style: italic;
  font-size: 18px;
}
.index_feedback h2 {
  font-size: 21px;
  font-weight: 700;
  font-family: Cambria, "Times New Roman", Georgia, "DejaVu Serif", serif;
  text-align: center;
}
.index_sidebar {
  display: flex;
  flex-direction: column;
  width: 224px;
  padding: 0 10px 10px;
}
.index_sidebar h2 {
  margin: 0;
}
.index_sidebar_btn {
  display: block;
  width: 180px;
  height: 50px;
  text-indent: -9999px;
  background-position: center;
  background-repeat: no-repeat;
}
.index_sidebar_btn--read {
  background-image: url("button_read.63b5bac5.jpg");
}
.index_sidebar_btn--read:hover, .index_sidebar_btn--read:active {
  background-image: url("button_read1.7af70c6a.jpg");
}
.index_sidebar_btn--download {
  background-image: url("button_download.910677e1.jpg");
}
.index_sidebar_btn--download:hover, .index_sidebar_btn--download:active {
  background-image: url("button_download1.fa94bfba.jpg");
}
.index_sidebar_btn--comprehend {
  background-image: url("button_understand.6fbfc074.jpg");
}
.index_sidebar_btn--comprehend:hover, .index_sidebar_btn--comprehend:active {
  background-image: url("button_understand1.1818db2f.jpg");
}
.index_sidebar_contacts {
  display: flex;
  flex-direction: column;
  width: 224px;
  padding: 10px;
  background-color: #f1f1f1;
  border: 2px dotted #c9a8c5;
}
.index_sidebar_contacts h3 {
  margin-bottom: 0;
}
.index_sidebar_contacts h3:first-of-type {
  margin-top: 0;
}
.index_sidebar_contacts small {
  margin-bottom: 10px;
  color: #888;
  font-size: 11px;
}
@media (max-width: 1000px) {
  .index_sidebar {
    width: 100%;
    padding: 0 15px;
  }
  .index_sidebar_contacts {
    width: 100%;
    padding: 0 15px;
  }
}
.share_button {
  --share-bg-h: 319;
  --share-bg-s: 25%;
  --share-bg-l: 44%;
  display: block !important;
  width: 200px;
  margin: 0 0 5px;
  padding: 5px 0 !important;
  border: 0;
  border-radius: 5px;
  color: #FFF !important;
  font-size: 14px !important;
  background: hsl(var(--share-bg-h), var(--share-bg-s), var(--share-bg-l)) !important;
  cursor: pointer;
}
.share_button:hover {
  background: hsl(var(--share-bg-h), var(--share-bg-s), calc(var(--share-bg-l) - 10%)) !important;
}
.share_button:active {
  background: hsl(var(--share-bg-h), var(--share-bg-s), calc(var(--share-bg-l) - 15%)) !important;
}
.share_button-copied {
  --share-bg-h: 124;
}
.share_button-twitter {
  --share-bg-h: 197;
  --share-bg-s: 100%;
  --share-bg-l: 47%;
}
.share_button-vk {
  --share-bg-h: 212;
  --share-bg-s: 50%;
  --share-bg-l: 52%;
}
.share_button-facebook {
  --share-bg-h: 221;
  --share-bg-s: 44%;
  --share-bg-l: 41%;
}
.CharactersPage {
  font-size: 15px;
  font-family: Georgia, "Times New Roman", "DejaVu Serif", serif;
}
.CharactersPage h2 {
  font-size: 20px;
}
.CharactersPage h3,
.CharactersPage h4 {
  margin-bottom: 0;
  font-family: Georgia, "Times New Roman", "DejaVu Serif", serif;
  font-size: 20px;
  font-style: italic;
  color: #999999;
  font-weight: normal;
}
.CharactersPage p {
  margin-top: 5px;
}
.CharactersPage_Brov {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.CharactersPage_Brov > img {
  max-width: 100%;
}
.CharactersPage_Brov > section {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 540px) {
  .CharactersPage_Brov {
    flex-direction: column;
  }
}
.CharactersPage_Subheading {
  color: #999999;
  font-size: 11px;
  font-family: Verdana, Tahoma, "DejaVu Sans", sans-serif;
}
.CharactersPage_Small {
  font-size: 11px;
  font-family: Verdana, Tahoma, "DejaVu Sans", sans-serif;
}
.CharactersPage_TwoColumns {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
@media (max-width: 640px) {
  .CharactersPage_TwoColumns {
    grid-template-columns: 1fr;
  }
}
.CharactersPage_GebniBig {
  max-width: 100%;
}
@media (max-width: 640px) {
  .CharactersPage_GebniBig {
    display: none;
  }
}
.CharactersPage_GebniSmall {
  max-width: 100%;
}
@media (min-width: 640px) {
  .CharactersPage_GebniSmall {
    display: none;
  }
}
.CharactersPage_Gebni {
  grid-gap: 30px;
}
.CharactersPage_Gebni h3,
.CharactersPage_Gebni .CharactersPage_Subheading {
  text-align: center;
}
.CharactersPage_OtherPolitics {
  grid-template-columns: 60% 1fr;
  grid-gap: 40px;
}
@media (max-width: 640px) {
  .CharactersPage_OtherPolitics {
    grid-template-columns: 1fr;
  }
}
.CharactersPage_OtherPolitics > section:first-child img {
  float: left;
  margin: 3px 10px;
}
@media (min-width: 640px) {
  .CharactersPage_OtherPolitics > section:last-child {
    text-align: right;
  }
}
.CharactersPage_Other section:first-child > div {
  flex-direction: row-reverse;
}
.CharactersPage_Other section:first-child > div img {
  margin: 0 5px 0 10px;
}
.CharactersPage_Other section:last-child > div img {
  margin: 0 10px 0 5px;
}
.CharactersPage_Other section > div {
  display: flex;
  align-items: flex-start;
}
@media (max-width: 640px) {
  .CharactersPage_Other section > div {
    flex-direction: row !important;
  }
  .CharactersPage_Other section > div img {
    margin: 0 10px 0 0 !important;
  }
}
@media (max-width: 425px) {
  .CharactersPage_Other section > div {
    flex-direction: column !important;
    align-items: center;
  }
}
.levels--official {
  font-family: "Courier New", "DejaVu Sans Mono", monospace;
}
.levels--comment {
  font-family: Georgia, "Times New Roman", "DejaVu Serif", serif;
  font-style: italic;
  font-size: 15px;
  margin-left: 15px;
  padding-bottom: 1em;
  color: #858585;
}

blockquote.levels--comment {
  margin-left: 40px;
}
.ArtPage_Grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  grid-gap: 20px;
}
.ArtPage_Grid a {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ArtPage_Grid a img {
  width: 180px;
}
.ArtPage_Grid a span {
  margin-top: 5px;
  text-align: center;
  font-weight: bold;
  font-family: Georgia, "Times New Roman", "DejaVu Serif", serif;
  font-size: 16px;
}
:root {
  --headerFont: Cambria, "Times New Roman", Georgia, "DejaVu Serif", serif;
  --faqFont: Georgia, "Times New Roman", "DejaVu Serif", serif;
  --linkColor: #5487aa;
  --hoverLinkColor: var(--linkColor);
  --activeLinkColor: var(--linkColor);
  --visitedLinkColor: #6a405d;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: Verdana, Tahoma, "DejaVu Sans", sans-serif;
  font-size: 14px;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3 {
  font-family: var(--headerFont);
}

h1 {
  font-size: 25px;
}

h2,
h3 {
  font-size: 18px;
}

a {
  color: var(--linkColor);
  text-decoration: none;
}

a:visited {
  color: var(--visitedLinkColor);
}

a:hover {
  color: var(--hoverLinkColor);
  text-decoration: underline;
}

a.active, a:active {
  color: var(--activeLinkColor);
}

.linkButton {
  display: inline;
  margin: 0;
  padding: 0;
  border: none;
  background: transparent;
  color: var(--linkColor);
  font-size: inherit;
  text-align: left;
  cursor: pointer;
}

.ta-center {
  text-align: center;
}

.ta-right {
  text-align: right;
}

.yt-lite {
  max-width: 580px;
  margin: auto;
}

.epigraph {
  width: 50%;
  max-width: 340px;
  min-width: 250px;
  text-indent: 0 !important;
  align-self: flex-end;
}

.chapter {
  margin-left: 2.6em;
}

.book-nav {
  width: 220px;
  font-family: Verdana, Tahoma, Arial, sans-serif;
  font-size: 11px;
  margin-top: 2.5em;
  margin-bottom: 0;
  line-height: 1.2em;
  padding: 1em;
  background: url(z2.f94f1419.jpg) no-repeat bottom, url(z1.6659f770.jpg) no-repeat top, #e9ebfc;
}

.book-nav h2 {
  font-family: Cambria, "Times New Roman", Georgia, "DejaVu Serif", serif;
  font-size: 1.6em;
  margin: 1em 0;
  color: black;
  text-align: center;
}

.book-nav > ul {
  margin-left: 0;
  padding-left: 0;
  list-style: none;
  margin-top: 0.5em;
}

.book-nav > ul > li {
  padding-left: 1.2em;
  background-repeat: no-repeat;
  background-position: -0.4em 0;
  font-weight: bold;
  margin-top: 0.7em;
}

.book-nav > ul > li a.active, .book-nav > ul > li a.active:hover, .book-nav > ul > li a.active:active {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  pointer-events: none;
}

.book-nav > ul > li.no-children {
  background-image: url(CircleSmall.bdb2ada9.png);
}

.book-nav > ul > li.closed {
  background-image: url(TriangleSmall.b3a806bc.png);
}

.book-nav > ul > li.open {
  background-image: url(TriangleDownSmall.e197a7bb.png);
}

.book-nav > ul > li .underlined {
  border-bottom: 1px dotted #888;
  cursor: pointer;
}

.book-nav > ul > li > ul {
  margin: 0.9em 0;
  padding-left: 1.2em;
  font-size: 11px;
  font-weight: normal;
}

.book-nav > ul > li > ul > li {
  margin-top: 0.5em;
}

.bedrograd-nav {
  display: flex;
  justify-content: space-between;
  font-size: 0.8em;
}

.bedrograd-nav a {
  display: flex;
  align-items: center;
}

.bedrograd-nav a img {
  margin: 0 10px;
}
/*# sourceMappingURL=index.482054fa.css.map */
