.page
  display: flex
  justify-content: center

  &_wrapper
    width: 100%
    max-width: 1000px

  main,
  aside
    @media (max-width: 1000px)
      max-width: 100vw
      padding: 0 15px

  &--noSidebar
    grid-template-columns: 1fr
    grid-template-areas: 'main' 'footer'

  &--sidebarRight
    grid-template-areas: 'main main main sidebar' 'footer footer footer footer'

    @media (max-width: 1000px)
      grid-template-areas: 'main' 'sidebar' 'footer'

  &--sidebarLeft
    grid-template-areas: 'sidebar main main main' 'footer footer footer footer'

    @media (max-width: 1000px)
      grid-template-areas: 'sidebar' 'main' 'footer'

  &--sidebarLeft,
  &--sidebarRight
    display: grid
    grid-gap: 15px
    margin-top: 15px

    main
      grid-area: main

    aside
      grid-area: sidebar

    footer
      grid-area: footer

    @media (max-width: 1000px)
      grid-template-columns: 1fr

  &--withText
    font-family: var(--faqFont)
    font-Size: 16px

    p,
    h3
      text-indent: 30px

  &--noIndent
    p,
    h3
      text-indent: 0 !important

  &_footer
    color: #888
    font-weight: normal
    font-size: .8em
    text-align: right
    font-style: italic

    @media (max-width: 1000px)
      margin-right: 10px
      margin-bottom: 10px