.BlockQuote
  --quoteColor: #b7afcc
  margin: 0
  font-family: Georgia, "Times New Roman", "DejaVu Serif", serif

  blockquote
    --quoteSize: 108px
    position: relative
    margin: 0
    padding: 0 70px
    font-size: 16px

    .quote_dots
      margin: -14px 0 30px
      color: var(--quoteColor)
      font-size: calc(var(--quoteSize) / 2)
      line-height: 0.3em

    &::before,
    &::after
      position: absolute
      color: var(--quoteColor)
      font-size: var(--quoteSize)

    &::before
      content: '«'
      top: -45px
      left: 5px

    &::after
      content: '»'
      bottom: -31px
      right: 15px

    @media (max-width: 1000px)
      --quoteSize: 50px
      padding: 0 35px

      .quote_dots
        margin: -7px 0 20px

      &::before
        top: -20px

      &::after
        bottom: -14px

  figcaption
    margin: 18px 0 38px auto
    width: 40%
    min-width: 300px
    color: var(--quoteColor)
    font-style: italic
    font-size: 18px
