.index
  &_feedback
    h2
      font-size: 21px
      font-weight: 700
      font-family: Cambria, "Times New Roman", Georgia, "DejaVu Serif", serif
      text-align: center

  &_sidebar
    display: flex
    flex-direction: column
    width: 224px
    padding: 0 10px 10px

    h2
      margin: 0

    &_btn
      display: block
      width: 180px
      height: 50px
      text-indent: -9999px
      background-position: center
      background-repeat: no-repeat

      &--read
        background-image: url('../../../public/images/button_read.jpg')

        &:hover,
        &:active
          background-image: url('../../../public/images/button_read1.jpg')

      &--download
        background-image: url('../../../public/images/button_download.jpg')

        &:hover,
        &:active
          background-image: url('../../../public/images/button_download1.jpg')

      &--comprehend
        background-image: url('../../../public/images/button_understand.jpg')

        &:hover,
        &:active
          background-image: url('../../../public/images/button_understand1.jpg')

    &_contacts
      display: flex
      flex-direction: column
      width: 224px
      padding: 10px
      background-color: #f1f1f1
      border: 2px dotted #c9a8c5

      h3
        margin-bottom: 0

        &:first-of-type
          margin-top: 0

      small
        margin-bottom: 10px
        color: #888
        font-size: 11px

    @media (max-width: 1000px)
      width: 100%
      padding: 0 15px

      &_contacts
        width: 100%
        padding: 0 15px