.ArtPage_Grid
  display: grid
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr))
  grid-gap: 20px

  a
    display: flex
    flex-direction: column
    align-items: center

    img
      width: 180px

    span
      margin-top: 5px
      text-align: center
      font-weight: bold
      font-family: Georgia, "Times New Roman", "DejaVu Serif", serif
      font-size: 16px
